import React, { useEffect } from "react";
import "./PopupToast.css";
export const PopupToast = (props) => {
  useEffect(() => {
    setTimeout(() => {
      props.close();
    }, 1500);
  }, []);
  return (
    <div className={props.className} onClick={() => props.close()}>
      <div
        style={{
          padding: "10px 25px",
          display: "flex",
          width: "max-content",
          alignItems: "center",
          fontFamily: "nexa TextBold",
        }}
      >
        {props.error}
        {props.icon}
      </div>
    </div>
  );
};
