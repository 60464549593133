import React, { Component } from "react";
// import ThemeWrapper, { AppContext } from "./ThemeWrapper";
import "./index.css";
import Outer from "./Outer";
import LoginForm from "./LoginForm";
import * as loginActions from "../_actions/loginActions";
import { connect } from "react-redux";

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      popupToast: false,
      error: {},
    };
  }
  componentDidMount() {
    const token = localStorage.getItem("admin");
    // const token2 = localStorage.getItem("super-admin");
    if (token) {
      this.props.history.push("/manager/home");
    }
  }
  setPopupToast = () => {
    this.setState({ popupToast: false });
  };
  handleChange = (e, name1) => {
    this.setState({ [name1]: e.target.value });
  };
  handleSubmit = () => {
    this.props.setSignInLoader(true);
    const user = {
      email: this.state.email.toLocaleLowerCase(),
      password: this.state.password,
    };
    this.props
      .dispatch(loginActions.LoginAdmin(user))
      .then((res) => {
        if( res?.status === true) {
          this.props.history.push("/manager/home");
          this.props.setSignInLoader(false);
        }
        else{
          this.props.history.push("/login");
          this.props.setSignInLoader(false);
        }
        
      })
      .catch((error) => {
        this.setState({ error: error, popupToast: true }, () =>
          this.props.setSignInLoader(false)
        );
      });
  };
  render() {
    return (
      // <Outer>
      <div className="loginouter">
        <LoginForm
          {...this.props}
          {...this.state}
          onChange={this.handleChange}
          handleSubmit={this.handleSubmit}
          setPopupToast={this.setPopupToast}
          error={this.state.error}
          loading={this.props.isLoading}
        />
      </div>
      // </Outer>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoading: state.isLoading,
    role: state.role,
  };
}

export default connect(mapStateToProps)(LoginPage);
