// const LOCATION = window.location.pathname;
import moment from "moment";
let initialState = {
  user: {},
  token: "",
  error: "",
  isLoading: true,
  venue_data: [],
  month: "",
  toggle: true,
  header: "/manager/home",
  status: "Active",
  chosenOffer: undefined,
  venuesSelected: [],
  selectedReferenceId: "",
  selectedVenues: [],
  billToData: {},
  advanceChange: 0,
  bookingInfoToHistory: "info",
  turfTownPayments: [],
  selectedPreviousBatchTTF: "",
  venueExpenseState: [],
  venueid: "",
  venueOffers: [],
  showBar: true,
  monthData: {},
  booking_success: false,
  booking_cancelled: false,
  booking_completed: false,
  double_click: 0,
  bookingData: {},
  repeat_booking_loader: false,
  repeat_booking_data: {},
  repeat_booking_booked_data: {},
  repeat_booking_advance_collected_popup: false,
  repeat_booking_failed_popup: false,
  repeat_page_completed: false,
  repeat_page_cancelled: false,
  calenderScrollPosition: 0,
  calenderChosenDate: moment().format("YYYYMMDD"),
  original_data:{},
  subscription_data:[],
  current_month_subscription_data:{},
  prev_subscription:[],
  selectedPreviousSubscription:"",
  venue:{},
  month_app_bookings:[],
  month_subscription:[],
  month_app_payment_stats:[],
  month_subscription_loading:true,
  app_payment:false,
  completed_app_payment:[],
  incomplete_app_payment:[],
  processing_app_payment:[],
  total_transaction_loading:false,
  total_completed_app_payments:[],
  games:[],
  report_users:[],
  isPageLoading:true
};


let user = JSON.parse(localStorage.getItem("user"));
initialState.user = user ? user : {};

export default (state = initialState, action) => {
  switch (action.type) {
    case "LOAD_START_USER":
      return Object.assign({}, state, { isLoading: action.payload });
    case "LOAD_START_PAGE":
      return Object.assign({}, state, { isPageLoading: action.payload });
    case "SET_INITIAL_STATE":
      return Object.assign({}, state, { user: action.payload });
    case "SET_LOGIN_ERROR":
      return Object.assign({}, state, { error: action.payload });
    case "LOAD_STOP_USER":
      return Object.assign({}, state, { isLoading: false });
      case "LOAD_STOP_PAGE":
        return Object.assign({}, state, { isPageLoading: false });
    case "LOGIN_USER_FAILIURE":
      return Object.assign({}, state, { error: action.payload });
    case "VENUE_DETAILS":
      return Object.assign({}, state, { venue_data: action.payload });
    case "VENUE_DETAILS_ERROR":
      return Object.assign({}, state, { error: action.payload });
    case "SELECTED_VENUE":
      return Object.assign({}, state, { venueid: action.payload });
    case "CURRENT_MONTH":
      return Object.assign({}, state, { month: action.payload });
    case "REPEAT_BOOKING_EDIT_DATA":
      return Object.assign({}, state, { repeat_data: action.payload });
    case "TOGGLE_ACTION":
      return Object.assign({}, state, { toggle: action.payload });
    case "OFFER_DISPATCHED":
      return Object.assign({}, state, { time: action.payload });
    case "/manager/home":
      return Object.assign({}, state, { header: action.payload });
    case "/manager/homePage":
      return Object.assign({}, state, { header: action.payload });
    case "/manager/repeatBooking":
      return Object.assign({}, state, { header: action.payload });
    case "/manager/alerts":
      return Object.assign({}, state, { header: action.payload });
    case "/manager/account":
      return Object.assign({}, state, { header: action.payload });
    case "Active":
      return Object.assign({}, state, { status: action.payload });
    case "Suspended":
      return Object.assign({}, state, { status: action.payload });
    case "CHOSEN_OFFER":
      return Object.assign({}, state, { chosenOffer: action.payload });
    case "SELECT_VENUE_LIST":
      return Object.assign({}, state, { venuesSelected: action.payload });
    case "EDIT_PROFILE_VENUE":
      return Object.assign({}, state, { selectedVenues: action.payload });
    case "LOG_OUT":
      return Object.assign({}, state);
    case "SELECT_REFERENCE_ID":
      return Object.assign({}, state, { selectedReferenceId: action.payload });
    case "UPDATE_USER":
      return Object.assign({}, state, { user: action.payload });
    case "BILLTODATA":
      return Object.assign({}, state, { billToData: action.payload });
    case "ADVANCE_CHANGE":
      return Object.assign({}, state, { advanceChange: action.payload });
    case "CHANGE_INFO_HISTORY":
      return Object.assign({}, state, { bookingInfoToHistory: action.payload });
    case "TURFTOWN_PAYMENTS_DATA":
      return Object.assign({}, state, { turfTownPayments: action.payload });
    case "CURRENT_POSITION":
      return Object.assign({}, state, { position: action.payload });
    case "TURFTOWN_PREVIOUS_BATCH":
      return Object.assign({}, state, {
        selectedPreviousBatchTTF: action.payload,
      });
    case "SHOW_BOTTOM_BAR":
      return Object.assign({}, state, { showBar: action.payload });
    case "VENUE_OFFERS":
      return Object.assign({}, state, { venueOffers: action.payload });
    case "VENUE_EXPENSE":
      return Object.assign({}, state, { venueExpenseState: action.payload });
    case "MONTH_DATA_CHANAGE":
      return Object.assign({}, state, { monthData: action.payload });
    case "DOUBLE_CLICK":
      return Object.assign({}, state, { double_click: action.payload });
    case "BOOKING_DATA_FUNCTION":
      return Object.assign({}, state, { bookingData: action.payload });
    case "GAME_WALLET_DATA_FUNCTION":
      return Object.assign({}, state, { gameWallet: action.payload });
    case "REPORT_DATA_FUNCTION":
      return Object.assign({}, state, { report_users: action.payload });
    case "CALENDER_CHOSEN_DATE":
      return Object.assign({}, state, { calenderChosenDate: action.payload });
    case "CALENDER_SCROLL_POSITION":
      return Object.assign({}, state, {
        calenderScrollPosition: action.payload,
      });

    case "BOOKING_SUCCESS":
      return Object.assign({}, state, { booking_success: action.payload });
    case "BOOKING_CANCELLED":
      return Object.assign({}, state, { booking_cancelled: action.payload });
    case "BOOKING_COMPLETED":
      return Object.assign({}, state, { booking_completed: action.payload });
    case "REPEAT_BOOKING_CANCELLED":
      return Object.assign({}, state, {
        repeat_page_cancelled: action.payload,
      });
    case "REPEAT_BOOKING_COMPLETED":
      return Object.assign({}, state, {
        repeat_page_completed: action.payload,
      });
    case "REPEAT_BOOKING_LOADER":
      return Object.assign({}, state, {
        repeat_booking_loader: action.payload,
      });
    case "REPEAT_BOOKING_DATA":
      return Object.assign({}, state, {
        repeat_booking_data: action.payload,
      });
    case "REPEAT_BOOKING_DATA_ORIGINAL":
      return Object.assign({}, state, {
        original_data: action.payload,
      });
    case "REPEAT_BOOKING_BOOKED_DATA":
      return Object.assign({}, state, {
        repeat_booking_booked_data: action.payload,
      });
    case "REPEAT_BOOKING_ADVANCE_COLLECTED":
      return Object.assign({}, state, {
        repeat_booking_advance_collected_popup: action.payload,
      });
    case "REPEAT_BOOKING_FAILED_POPUP":
      return Object.assign({}, state, {
        repeat_booking_failed_popup: action.payload,
      });
    case "SUBSCRIPITION_DATA":
      return Object.assign({}, state, {
        subscription_data: action.payload,
      });
    case "CURRENT_SUBSCRIPITION_DATA":
      return Object.assign({}, state, {
        current_month_subscription_data: action.payload,
      });
    case "ALL_SUBSCRIPITION_DATA":
      return Object.assign({}, state, {
        prev_subscription: action.payload,
      });
    // selectedPreviousBatchTTF

    case "TURFTOWN_PREVIOUS_SUBSCRIPITION":
      return Object.assign({}, state, {
        selectedPreviousSubscription: action.payload,
      });
    case "ACTIVE_VENUE":
      return Object.assign({}, state, {
        venue: action.payload,
      });
    case "CURRENT_BALANCE":
      return Object.assign({}, state, {
        current_balance: action.payload,
      });
    case "ALL_BOOKINGS":
      return Object.assign({}, state, {
        all_bookings: action.payload,
      });
    case "NEW_SUBSCRIPTION":
      return Object.assign({}, state, {
        new_subscription: action.payload,
      });
    case "PAYOUT_LIST":
      return Object.assign({}, state, {
        payout_list: action.payload,
      });
      case "IN_PROCESS_PAYOUT":
      return Object.assign({}, state, {
        in_process_payout: action.payload,
      });
    case "MONTH_APP_PAYMENTS":
      return Object.assign({}, state, {
        month_app_bookings: action.payload,
      });

    case "MONTH_SUBSCRIPTION":
      return Object.assign({}, state, {
        month_subscription: action.payload,
      });

    case "MONTH_SUBSCRIPTION_LOADING":
      return Object.assign({}, state, {
        month_subscription_loading: action.payload,
      });

    case "APP_PAYMENT_TRANSFFERED":
      return Object.assign({}, state, {
        app_payment: action.payload,
      });

    case "COMPLETED_PAYMENTS":
      return Object.assign({}, state, {
        completed_app_payment: action.payload,
      });

    case "INCOMPLETE_PAYMENTS":
      return Object.assign({}, state, {
        incomplete_app_payment: action.payload,
      });

    case "PROCESSING_PAYMENTS":
      return Object.assign({}, state, {
        processing_app_payment: action.payload,
      });

    case "TOTAL_TRANSACTION_LOADING":
      return Object.assign({}, state, {
        total_transaction_loading: action.payload,
      });

    case "TOTAL_TRANSACTIONS":
      return Object.assign({}, state, {
        total_completed_app_payments: action.payload,
      });

    case "BATCH_GAMES":
      return Object.assign({}, state, {
        games: action.payload,
      });
      case "AD_CAMPAIGN":
        return Object.assign({}, state, {
          ad_campaign: action.payload,
        });
    default:
      return state;
  }
};
