import LoginPage from "./LoginPage";
import React, { Component, Fragment,Suspense, lazy  } from "react";
import { Switch, Route, Router, BrowserRouter } from "react-router-dom";
import ThemeWrapper, { AppContext } from "./Layouts/ThemeWrapper";
import "./custom-font.css";
import Logo from "./Public/images/logo.png";
import * as loginActions from "./_actions/loginActions";
import Loadable from "react-loadable";

const ForgotPassword = lazy(() => import('./LoginForms/ForgotPasswordForm'));
const UpdatePassword = lazy(() => import('./LoginForms/ChangePasswordForm'));
const Application = lazy(() => import('./Application'));
const Login =  lazy(()=>import("./LoginPage/index"));


// const Login = Loadable({
//   loader: () => import("./LoginPage/index"),
//   loading() {
//     return <span style={{ width: "100%", height: "100%" ,backgroundColor:"red"}}></span>;
//   },
// });
class App extends React.Component {
  constructor(props) {
    super();
    this.showNotification = this.showNotification.bind(this);
    this.state = {
      loading: true,
      signInLoader: false,
    };
  }

  setSignInLoader = (e) => {
    this.setState({ signInLoader: e });
  };
  fakeRequest = () => {
    return new Promise((resolve) => setTimeout(() => resolve(), 0));
  };
  async componentDidMount() {
    this.fakeRequest().then(() => {
      const el = document.querySelector(".loader-container");
      if (el) {
        el.remove(); // removing the spinner element
        this.setState({ loading: false }); // showing the app
      }
    });
    if (window.performance) {
      if (performance.navigation.type == 1) {
        const x = await localStorage.getItem("token");
        loginActions.SetAccessToken1(x);
        localStorage.setItem("reload",true)
        this.setState({ loading: false });
      } else {
        console.log("This page is not reloaded");
      }
    }

    if (!("Notification" in window)) {
      console.log("This browser does not support desktop notification");
    } else {
      Notification.requestPermission();
    }
  }
  componentWillUnmount(){
    localStorage.setItem("reload",true)
  }
  showNotification() {
    var content = {
      body: "Notification working",
      icon: Logo,
      dir: "ltr",
    };
    new Notification("Notification test", content);
  }

  render() {
    if (this.state.loading) {
      return null; //app is not ready (fake request is in process)
    }
    // window.addEventListener("message", message => {
    //   console.log(message.data) // Wayne is coming!!!
    //   window.ReactNativeWebView.postMessage('Client received data')
    // });
    return (
      <ThemeWrapper>
        <AppContext.Consumer>
          {(changeMode) => (
            <BrowserRouter>
              <Switch>
              {/* <Suspense fallback={<div>Loading...</div>}> */}
              <Suspense fallback={<div className="loader" style={{
                  width:"100vw",
                  height:"100vh",
                  display:" flex",
                  justifyContent: "center",
                  alignItems: "center"
              }}>
                    <span className="spinner" style={{marginTop:150}}></span>
                </div>}>
                <Route
                  exact
                  path="/login"
                  render={(props) => (
                    <Login
                      {...props}
                      signInLoader={this.state.signInLoader}
                      setSignInLoader={this.setSignInLoader}
                      type="admin"
                    />
                  )}
                />
                <Route
                  path="/forgot-password"
                  exact
                  component={ForgotPassword}
                />
                <Route
                  path="/reset-password/:id"
                  render={(props) => (
                    <UpdatePassword {...props} changeMode={changeMode} />
                  )}
                />
                <Route
                  path="/manager"
                  render={(props) => {
                    return (
                      <Application
                        {...props}
                        type="admin"
                        changeMode={changeMode}
                        showNotification={this.showNotification}
                      />
                    );
                  }}
                />

                <Route
                  path="/"
                  render={(props) => (
                    <Application
                      {...props}
                      type="landing"
                      changeMode={changeMode}
                    />
                  )}
                />
                 </Suspense>

              </Switch>
            </BrowserRouter>
          )}
        </AppContext.Consumer>
      </ThemeWrapper>
    );
  }
}

export default App;
