import axios from "axios";
import moment from "moment";
import { batch } from "react-redux";
// import moment from "moment";

const baseURL1 =process.env.REACT_APP_BASE_URL;

export const baseUrl = () => { 
  // return "http://ec2-13-232-87-232.ap-south-1.compute.amazonaws.com";
  // return "http://qa.turftown.in";
  //  return "https://stage.turftown.in";
  return baseURL1;
    //  return "https://devstage.turftown.in"
    //return "https://true-moles-hear.loca.lt"
  //return"https://api.turftown.in"
  // return "http://192.168.1.4:3040"
  // return "http://192.168.0.107:3040"
};
export const instance = axios.create({

  // baseURL: baseUrl(),
  // baseURL: "http://ec2-13-233-56-234.ap-south-1.compute.amazonaws.com", /// stage server
  // baseURL: "http://ec2-13-232-87-232.ap-south-1.compute.amazonaws.com", /// development server
  // baseURL: "http://qa.turftown.in",
  baseURL: baseURL1,
  // baseURL: "https://stage.turftown.in",
  // baseURL: "https://turftown.in",
    //  baseURL: "https://devstage.turftown.in",
   //baseURL :"https://true-moles-hear.loca.lt",
  //baseURL: "https://api.turftown.in",
  // baseURL: "http://192.168.1.4:3040",
  timeout: 100000,
});
export const new_instance = axios.create({
  // baseURL: baseUrl(),
  // baseURL: "http://ec2-13-233-56-234.ap-south-1.compute.amazonaws.com", /// stage server
  // baseURL: "http://ec2-13-232-87-232.ap-south-1.compute.amazonaws.com", /// development server
  // baseURL: "http://qa.turftown.in",
  // baseURL: "https://stage.turftown.in",
  // baseURL: "https://vm.turftown.in",
  baseURL: baseURL1,
  //  baseURL: "https://stage.turftown.in",
  //baseURL: "https://api.turftown.in",
  //  baseURL: "https://devstage.turftown.in",
   // baseURL :"https://true-moles-hear.loca.lt",
  // baseURL: "https://turftown.in",
  // baseURL: "http://192.168.1.5:3040",
  timeout: 100000,
}); 
export let axiosConfig = {
  headers: {
    "Content-Type": "application/json",
    "x-access-token": "",
    // "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjVlNTkxMDllMTVjOTk5MjQ0NjAwZjM3NCIsInVzZXJuYW1lIjoia3VtYXJAdHVyZnRvd24uaW4iLCJyb2xlIjoidmVudWVfbWFuYWdlciIsIm5hbWUiOiJrdW1hciIsImlhdCI6MTU4NzMyMTcxNX0.WYW_mVpaO00mKrD-Ca-eG-wGNcfPuxugf9dzUtCyMSI",
    "Access-Control-Allow-Origin": "*",
  },
};

//  export let axiosConfig = {
//     headers: {
//       "Content-Type": "application/json",
//       "Access-Control-Allow-Origin": "*"
//       // "x-access-token":
//       //   ""
//     }
//   };
//   instance.interceptors.response.use(

//     function(response) { return response;},
//     function(error) {
//       // if(error.message == "Network Error"){
//       //   toast.info("You seem to be offline. Please check your internet connection")
//       // }
//       // else{
//         return Promise.reject(error)
//       // }
//     }
//     );
export const indianRupeeComma = (value) => {
  var x = value ? value : 0;
  x = x.toString();
  var lastThree = x.substring(x.length - 3);
  var otherNumbers = x.substring(0, x.length - 3);
  if (otherNumbers != "") lastThree = "," + lastThree;
  var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;

  return res;
  // value.toLocaleString('en-IN');
};

export const indianRupeeComma1 = (value) => {
  let x = value ? Number(value) : 0;
  let num = Number.isInteger(x)
    ? new Number(x).toLocaleString("hi-IN")
    : new Number(x).toLocaleString("hi-IN");
  return num;
};

export const getRupeeSymbol = () => {
  let input = "\u20B9";
  return input.replace(/\\u(\w{4,4})/g, function (a, b) {
    var charcode = parseInt(b, 16);
    return String.fromCharCode(charcode);
  });
};

export const toggleDrawer = () => {
  return (dispatch, getState) => {
    let toggle_open = getState().toggle;

    dispatch({ type: "TOGGLE_ACTION", payload: !toggle_open });
  };
};
export const SetAccessToken = (response) => {
  instance.defaults.headers.post["x-access-token"] =
    response.data.token.toString();
  axiosConfig.headers["x-access-token"] = response.data.token.toString();
  instance.defaults.headers.post["Content-Type"] = "application/json";
  new_instance.defaults.headers.post["x-access-token"] =
  response.data.token.toString();
  new_instance.defaults.headers.post["Content-Type"] = "application/json";
};
export const SetAccessToken1 = (response) => {
  if (response) {
    instance.defaults.headers.post["x-access-token"] = response.toString();
    axiosConfig.headers["x-access-token"] = response.toString();
    instance.defaults.headers.post["Content-Type"] = "application/json";
    new_instance.defaults.headers.post["x-access-token"] = response.toString();
    axiosConfig.headers["x-access-token"] = response.toString();
    new_instance.defaults.headers.post["Content-Type"] = "application/json";
  }
};
export const LoginAdmin = (user) => {
  return (dispatch, getState) => {
    return instance
      .post("/api/admin/admin_login", {
        username: user.email, //String
        password: user.password, //String
      })
      .then(function (response) {
        if (
          response.data.status == "success" &&
          response.data.role !== "super_admin"
        ) {
          let token = response.data.token;
          SetAccessToken(response);
          localStorage.setItem("token", token);
          localStorage.setItem("admin", token);
          localStorage.setItem("user", JSON.stringify(response.data.data));
          dispatch(
            { type: "SET_INITIAL_STATE", payload: response.data.data },
            { type: "SELECTED_VENUE", payload: response.data.data.venue[0]._id }
          );

          dispatch({
            type: "SELECTED_VENUE",
            payload: response.data.data.venue[0]._id,
          });
          return response.data.data;
        } else if (response.data.status == "failed") {
          dispatch({ type: "SET_LOGIN_ERROR", payload: response.data.message });
          return Promise.reject(response.data);
        }
      })
      .catch(function (error) {
        dispatch({
          type: "LOGIN_USER_FAILIURE",
          payload: error.response,
        });
        return Promise.reject(error);
      });
  };
};

export const forgotPassword = (body) => {
  return (dispatch, getState) => {
    return instance
      .post("/api/admin/forget_password", body, axiosConfig)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };
};

export const resetPassword = (body) => {
  return (dispatch, getState) => {
    
    return instance
      .post("/api/admin/reset_password", body, axiosConfig)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };
};

export const getVenue = (venue_Array) => {
  return (dispatch, getState) => {
    return instance
      .post("/api/admin/venue_list_by_id", { venue: venue_Array }, axiosConfig)
      .then((res) => {
        dispatch({
          type: "VENUE_DETAILS",
          payload: res.data.data,
        });
        return res;
      })
      .catch((err) => {
        dispatch({
          type: "VENUE_DETAILS_ERROR",
          payload: err.response,
        });
        return Promise.reject(err);
      });
  };
};

export const setHeader = (type, data) => {
  return (dispatch, getstate) => {
    dispatch({
      type: type,
      payload: data,
    });
  };
};

export const setStatus = (type, data) => {
  return (dispatch, getState) => {
    dispatch({ type: type, payload: data });
  };
};

export const venueExpenseFunctions = (data) => {
  return (dispatch, getState) => {
    dispatch({ type: "VENUE_EXPENSE", payload: data });
  };
};
export const repeatBookingLoaderFunctions = (data) => {
  return (dispatch, getState) => {
    dispatch({ type: "REPEAT_BOOKING_LOADER", payload: data });
  };
};
export const repeatBookingDataFunctions = (data) => {
  return (dispatch, getState) => {
    dispatch({ type: "REPEAT_BOOKING_DATA", payload: data });
    if(data.original_data)
    dispatch({ type: "REPEAT_BOOKING_DATA_ORIGINAL", payload: data.original_data });
  };
};
export const clearrepeatBookingDataFunctions = () => {
  return (dispatch, getState) => {
    dispatch({ type: "REPEAT_BOOKING_DATA", payload: {} });
    dispatch({ type: "REPEAT_BOOKING_DATA_ORIGINAL", payload: {} });
  };
};
export const setInitailStateFunction = (data) => {
  return (dispatch, getState) => {
    dispatch({ type: "SET_INITIAL_STATE", payload: data });
  };
};

export const doubleClickVenuePopup = (click) => {
  return (dispatch, getState) => {
    dispatch({ type: "DOUBLE_CLICK", payload: click });
  };
};

export const isLoadingFunction = (data) => {
  return (dispatch, getState) => {
    dispatch({ type: "LOAD_START_USER", payload: data });
  };
};
export const pageLoadFunction = (data) => {
  return (dispatch, getState) => {
    dispatch({ type: "LOAD_START_PAGE", payload: data });
  };
};
export const chosenOfferFunction = (type, data) => {
  return (dispatch, getState) => {
    dispatch({ type: type, payload: data });
  };
};

export const getVenueDetails = (venueid) => {
  return (dispatch, getState) => {
    return instance
      .post(`/api/admin/get_venue/${venueid}`, {}, axiosConfig)
      .then((res) => {
        dispatch({type:"ACTIVE_VENUE",payload:res.data.data})
        return res.data.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };
};

export const get_admin_details = (role) => {
  return instance
    .post(`/api/admin/admin_details`, { role }, axiosConfig)
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};
export const get_repeat_booking_list = (body) => {
  let ids = body.id.map((key)=>key.booking_id)
  return (dispatch, getState) => {
    return new_instance
      .post(`/api/v2/regular/time_group_details`, {booking_id:ids}, axiosConfig)
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };
};
export const create_regular = (body) => {
  return (dispatch, getState) => {
    return new_instance
      .post(`/api/v2/regular/create_regular`, body, axiosConfig)
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };
};
export const regular_list = (body) => {
  return (dispatch, getState) => {
    return new_instance
      .post(`/api/v2/regular/regular_list`, body, axiosConfig)
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };
};
export const complete_regular = (body) => {
  return (dispatch, getState) => {
    return new_instance
      .post(`/api/v2/regular/complete_regular`, body, axiosConfig)
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };
};

export const regularSearch = (body) => {
  return (dispatch, getState) => {
    return new_instance
      .post(`/api/v2/regular/regular_list_search`, body, axiosConfig)
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };
};

export const regular_details = (body) => {
  return (dispatch, getState) => {
    return new_instance
      .post(`/api/v2/regular/regular_details`, body, axiosConfig)
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };
};
export const book_regular = (id, body) => {
  return (dispatch, getState) => {
    return new_instance
      .post(`/api/v2/regular/book_regular/${id}`, body, axiosConfig)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };
};

export const edit_regular = (body) => {
  return (dispatch, getState) => {
    return new_instance
      .post(`/api/v2/regular/edit_regular`, body, axiosConfig)
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };
};

export const get_manager_details = (id) => {
  return instance
    .post(`/api/admin/get_manager_details/${id}`, {}, axiosConfig)
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const get_staff_details = (id) => {
  return instance
    .post(`/api/admin/get_staff_details/${id}`, {}, axiosConfig)
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const venueChange = (id) => {
  return (dispatch, getState) => {
    dispatch({ type: "SELECTED_VENUE", payload: id });
  };
};
export const BookingSuccess = (res) => {
  return (dispatch, getState) => {
    dispatch({ type: "BOOKING_SUCCESS", payload: res });
  };
};
export const BookingCancelled = (res) => {
  return (dispatch, getState) => {
    dispatch({ type: "BOOKING_CANCELLED", payload: res });
  };
};
export const RepeatBookingAdvanceCollected = (res) => {
  return (dispatch, getState) => {
    dispatch({ type: "REPEAT_BOOKING_ADVANCE_COLLECTED", payload: res });
  };
};
export const CloseRepeatBookingFailedPopup = (res) => {
  return (dispatch, getState) => {
    dispatch({ type: "REPEAT_BOOKING_FAILED_POPUP", payload: res });
  };
};
export const BookingCompletedSnackBar = (res) => {
  return (dispatch, getState) => {
    dispatch({ type: "BOOKING_COMPLETED", payload: res });
  };
};
export const RepeatBookingCompletedSnackBar = (res) => {
  return (dispatch, getState) => {
    dispatch({ type: "REPEAT_BOOKING_COMPLETED", payload: res });
  };
};

export const RepeatBookingCancelledSnackBar = (res) => {
  return (dispatch, getState) => {
    dispatch({ type: "REPEAT_BOOKING_CANCELLED", payload: res });
  };
};

export const OfferCreatedSuccess = (res) => {
  return (dispatch, getState) => {
    dispatch({ type: "OFFER_CREATED", payload: res });
  };
};
export const venueOffersFuncion = (offers) => {
  return (dispatch, getState) => {
    dispatch({ type: "VENUE_OFFERS", payload: offers });
  };
};

export const updateAdvanceChange = (advance) => {
  return (dispatch, getState) => {
    dispatch({ type: "ADVANCE_CHANGE", payload: advance });
  };
};

export const selectedPreviousBatch = (batch) => {
  return (dispatch, getState) => {
    dispatch({ type: "TURFTOWN_PREVIOUS_BATCH", payload: batch });
  };
};

export const CheckAdmin = (user_id) => {
  return (dispatch) => {
    return instance
      .post(`/api/admin/check_admin/${user_id}`, {}, axiosConfig)
      .then((response1) => {
        return response1;
        // this.renderEvent(response1.data.data)
        // this.LoadingBar.complete()
        // this.calculateHoursRevenue(response1.data.data)
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};

export const CheckPassword = (id, body, setStages) => {
  return instance
    .post(`/api/admin/check_password/${id}`, body, axiosConfig)
    .then((res) => {
      if (res.data.status == "failed") {
        return res.data.message;
      }
      res.data.status !== "failed" && setStages(2);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const setPassword = (id, body, setStages) => {
  let x = { userId: id, password: body.password };
  return instance
    .post(`/api/admin/set_password`, x, axiosConfig)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};
export const BookingHistoryByVenue = (bodyData) => {
  return (dispatch, getState) => {
    return instance
      .post(`/api/user/booking_history_by_venue`, bodyData, axiosConfig)
      .then((response1) => {
        return response1.data.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};

export const BookingHistory = (venueid, bodyData) => {
  return (dispatch, getState) => {
    return instance
      .post(
        `/api/user/booking_history_by_time/${venueid}`,
        bodyData,
        axiosConfig
      )
      .then((response1) => {
        return response1.data.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};

export const BookingHistoryCancelled = (bodyData) => {
  return (dispatch, getState) => {
    return instance
      .post(`/api/test/booking_history_by_cancelled`, bodyData, axiosConfig)
      .then((response1) => {
        return response1.data.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};

export const BookingHistoryLive = (bodyData) => {
  return (dispatch, getState) => {
    return instance
      .post(`/api/test/booking_history_by_live`, bodyData, axiosConfig)
      .then((response1) => {
        return response1.data.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};
export const InCompeleteBookings = (venueid, bodyData) => {
  return (dispatch, getState) => {
    return instance
      .post(
        `/api/user/cancelled_booking_history_by_time/${venueid}`,
        bodyData,
        axiosConfig
      )
      .then((response1) => {
        let data =
          response1.data.data.length > 0
            ? response1.data.data.filter(
                (key) => key.booking_status !== "timeout"
              )
            : [];
        return data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};

export const CancelledBookings = (venueid, bodyData) => {
  return (dispatch, getState) => {
    return instance
      .post(
        `/api/user/cancelled_booking_history_by_time/${venueid}`,
        bodyData,
        axiosConfig
      )
      .then((response1) => {
        let data =
          response1.data.data.length > 0
            ? response1.data.data.filter(
                (key) => key.booking_status !== "timeout"
              )
            : [];
        return data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};

export const slotSelectionVmBooking = (body) => {
  return (dispatch) => {
    return instance
      .post(`/api/test/vm_booking`, body, axiosConfig)
      .then((res) => {
        return res.data.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};

export const CancelManagerBooking = (id1, body,booking_details) => {
  return (dispatch) => {
    let url =  (booking_details?.booking_type !== 'app' || !booking_details?.game) ? `/api/user/cancel_manager_booking/${id1}` : `/api/v2/game/cancel_game_by_vm/${id1}`
    return instance
      .post(url, body, axiosConfig)
      .then((res) => {
        return res.data.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};
export const CancelManagerBooking1 = (id1, body,booking_details) => {
  return (dispatch) => {
    let url = (booking_details?.booking_type !== 'app' || !booking_details?.game) ? `/api/user/cancel_manager_booking/${id1}` : `/api/v2/game/cancel_game_by_vm/${id1}`
    return instance
      .post(url, body, axiosConfig)
      .then((res) => {
        return res.data.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};
export const CancelManagerRepeatBooking = (body) => {
  return (dispatch) => {
    return (
      new_instance
        // .post(`/api/user/cancel_manager_repeat_booking/${id1}`, body, axiosConfig)
        .post(
          `/api/v2/regular/cancel_individual_regular_booking`,
          body,
          axiosConfig
        )
        .then((res) => {
          return res.data.data;
        })
        .catch((error) => {
          return Promise.reject(error);
        })
    );
  };
};
export const cancel_time_group = (body) => {
  return (dispatch) => {
    return new_instance
      .post(`/api/v2/regular/cancel_time_group`, body, axiosConfig)
      .then((res) => {
        return res.data.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};

export const currentMonth = (month) => {
  return (dispatch, getState) => {
    dispatch({ type: "CURRENT_MONTH", payload: month });
  };
};
export const calenderScrollPositionFunction = (body) => {
  return (dispatch, getState) => {
    dispatch({ type: "CALENDER_SCROLL_POSITION", payload: body });
  };
};
export const changeInfoHistroy = (body) => {
  return (dispatch, getState) => {
    dispatch({ type: "CHANGE_INFO_HISTORY", payload: body });
  };
};

export const turfTownPaymentsFunction = (body) => {
  return (dispatch, getState) => {
    dispatch({ type: "TURFTOWN_PAYMENTS_DATA", payload: body });
  };
};

export const billToDataFunction = (body) => {
  return (dispatch, getState) => {
    dispatch({ type: "BILLTODATA", payload: body });
  };
};
export const PastRepeatedBookingHistory = (bodyData) => {
  return (dispatch, getState) => {
    return instance
      .post(`/api/v2/bookings/repeat_bookings_list_past`, bodyData, axiosConfig)
      .then((response1) => {
        return response1.data.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};
export const RegularBookingsName = (id) => {
  return (dispatch, getState) => {
    return new_instance
      .get(`/api/v2/venue/regular_bookings/${id}`, axiosConfig)
      .then((response1) => {
        return response1.data.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};

export const RepeatBookingGroup = (bodyData) => {
  return (dispatch, getState) => {
    return new_instance
      .post(`/api/v2/bookings/repeat_booking_details`, bodyData, axiosConfig)
      .then((response1) => {
        return response1.data.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};

export const ActiveRepeatedBookingHistory = (bodyData) => {
  return (dispatch, getState) => {
    return new_instance
      .post(
        `/api/v2/bookings/repeat_bookings_list_active`,
        bodyData,
        axiosConfig
      )
      .then((response1) => {
        return response1.data.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};

export const AppBookings = (id, body) => {
  return (dispatch) => {
    return instance
      .post(
        `/api/user/booking_history_from_app_by_venue/${id}`,
        body,
        axiosConfig
      )
      .then((res) => {
        // dispatch.turfTownPaymentsFunction(res.data.data);
        return res.data.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};

export const AppBookings1 = (body) => {
  return (dispatch, getState) => {
    return instance
      .post(`/api/test/turftown_app_payments_details/`, body, axiosConfig)
      .then((response1) => {
        return response1.data.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};
export const AppBookingsPreviousBatch1 = (body) => {
  return (dispatch, getState) => {
    return instance
      .post(
        `/api/test/turftown_payment_previous_batch_details1/`,
        body,
        axiosConfig
      )
      .then((response1) => {
        return response1.data.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};
export const AppBookingsPreviousBatch = (body) => {
  return (dispatch, getState) => {
    return instance
      .post(
        `/api/test/turftown_payment_previous_batch_details/`,
        body,
        axiosConfig
      )
      .then((response1) => {
        return response1.data.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};

export const RevenueReport = (body) => {
  return (dispatch) => {
    return (
      instance
        .post(`/api/user/revenue_report_months_v2 `, body, axiosConfig)
        // .post(`/api/test/revenue_report_months`, body, axiosConfig)
        .then((res) => {
          return res.data.data;
        })
        .catch((error) => {
          return Promise.reject(error);
        })
    );
  };
};

export const RevenueReportMonthsPage = (body) => {
  return (dispatch) => {
    return instance
      .post(`/api/test/revenue_report_months1 `, body, axiosConfig)
      .then((res) => {
        return res.data.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};

export const RevenueReportMonths = (body) => {
  return (dispatch) => {
    return instance
      .post(`/api/user/revenue_report_months `, body, axiosConfig)
      .then((res) => {
        return res.data.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};
export const BookingCompletedList = (body) => {
  return (dispatch) => {
    return instance
      .post(`/api/user/booking_completed_list_by_venue `, body, axiosConfig)
      .then((res) => {
        return res.data.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};

export const invoiceAdvance = (body) => {
  return (dispatch) => {
    return instance
      .post(`/api/user/get_invoice_advance`, body, axiosConfig)
      .then((res) => {
        return res.data.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};

export const SlotsAvailable = (venueid, body) => {
  return (dispatch) => {
    return (
      instance
        .post(`/api/test/slots_available1/${venueid}`, body, axiosConfig)
        // .post(`/api/user/slots_available/${venueid}`, body, axiosConfig)
        .then((res) => {
          return res.data.data;
        })
        .catch((error) => {
          return Promise.reject(error);
        })
    );
  };
};
export const SlotsAvailableRepeat = (venueid, body) => {
  return (dispatch) => {
    return new_instance
      .post(`/api/user/slots_value/${venueid}`, body, axiosConfig)
      .then((res) => {
        return res.data.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};

export const BookSlotRepeat = (venueid, body) => {
  return (dispatch) => {
    return instance
      .post(`/api/v2/user/book_slot_for_value1/${venueid}`, body, axiosConfig)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error;
      });
  };
};
export const BookSlot = (venueid, body) => {
  return (dispatch) => {
    return instance
      .post(`/api/user/book_slot_for_value1/${venueid}`, body, axiosConfig)
      .then((res) => {
        if (res.data.status === "success") {
          dispatch({ type: "BOOKING_SUCCESS", payload: true });
        }
        return res.data.data;
      
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};
export const BookSlotAdmin = (venueid, body) => {
  return (dispatch) => {
    return instance
      .post(`/api/user/book_slot_for_admin1/${venueid}`, body, axiosConfig)
      .then((res) => {
        if (res.data.status === "success") {
          dispatch({ type: "BOOKING_SUCCESS", payload: true });
        }
        return res.data.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};
export const SlotList = (venueid, body) => {
  return (dispatch) => {
    return instance
      .post(`/api/user/slots_list/${venueid}`, body, axiosConfig)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};
export const venue_analytics = (body) => {
  return (dispatch) => {
    return instance
      .post(`/api/test/venue_analytics_v2`, body, axiosConfig)
      .then((res) => {
        dispatch({
          type: "PENDING_FEE",
          payload:res.data.data,
        });
        return res.data.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};
export const individualBookingDetails = (body) => {
  return (dispatch) => {
    return instance
      .post(`/api/test/booking_details`, body, axiosConfig)
      .then((res) => {
        return res.data.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};

export const individualBookingDetailsWithId = (body) => {
  return (dispatch) => {
    return instance
      .post(`/api/test/booking_details1`, body, axiosConfig)
      .then((res) => {
        return res.data.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};

export const EditSlot = (venueid, body) => {
  return (dispatch) => {
    return instance
      .post(`/api/test/modify_booking/${venueid}`, body, axiosConfig)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};
export const EditSlot1 = (venueid, body) => {
  return (dispatch) => {
    return instance
      .post(`/api/test/modify_booking1/${venueid}`, body, axiosConfig)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};

// export const BookSlot = (venueid, body) => {
//   return (dispatch) => {
//     return instance
//       .post(`/api/user/book_slot_for_admin1/${venueid}`, body, axiosConfig)
//       .then((res) => {
//         return res;
//       })
//       .catch((error) => {
//         return Promise.reject(error);
//       });
//   };
// };

// export const CollectionReport = () => {
//   return (dispatch) => {
//     return instance
//       .post(`/api/user//${venueid}`, body, axiosConfig)
//       .then((res) => {
//         return res;
//       })
//       .catch((error) => {
//         return Promise.reject(error);
//       });
//   };
// };
export const BookingCompleted = (id, body) => {
  return (dispatch) => {
    let base_url_type = baseUrl()
    return axios.post(`${base_url_type}/api/user/booking_completed/${id}`, body, axiosConfig)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};
export const BookingCompletedRepeatBooking = (id, body) => {
  return (dispatch) => {
    return instance
      .post(`/api/test/booking_completed1/${id}`, body, axiosConfig)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};
export const InvoiceHistory = (venueid, body) => {
  return (dispatch) => {
    return instance
      .post(
        `/api/user/invoice_history_by_group_id/${venueid}`,
        body,
        axiosConfig
      )
      .then((res) => {
        return res.data.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};

export const InvoiceHistoryByTime = (venueid, body) => {
  return (dispatch) => {
    return instance
      .post(
        `/api/user/invoice_history_by_group_id_by_time/${venueid}`,
        body,
        axiosConfig
      )
      .then((res) => {
        return res.data.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};

export const BookingHistoryByGroup = (venueid, body) => {
  return (dispatch) => {
    return instance
      .post(
        `/api/user/booking_history_by_group_id/${venueid}`,
        body,
        axiosConfig
      )
      .then((res) => {
        return res.data.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};

export const UpdateInvoice = (venueid, body) => {
  return (dispatch) => {
    return instance
      .post(`/api/user/update_invoice/${venueid}`, body, axiosConfig)
      .then((res) => {
        return res.data.message;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};

export const UpdateInvoiceAddress = (body, page) => {
  if (page !== "invoicePreview") {
    return (dispatch) => {
      return instance
        .post(`/api/user/update_invoice`, body, axiosConfig)
        .then((res) => {
          return res.data.message;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    };
  } else {
    return instance
      .post(`/api/user/update_invoice`, body, axiosConfig)
      .then((res) => {
        return res.data.message;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
};

export const UpdateInvoiceAmount = (body) => {
  return (dispatch) => {
    return new_instance
      .post(`/api/v2/regular/collect_payment`, body, axiosConfig)
      .then((res) => {
        return res.data.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};
export const customerNameApi = (body) => {
  return (dispatch) => {
    return new_instance
      .post(`/api/v2/regular/regular_search`, body, axiosConfig)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};

export const customerNumberApi = (body) => {
  return (dispatch) => {
    return new_instance
      .post(`/api/v2/regular/regular_search_number`, body, axiosConfig)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};

export const RepeatBookingTranscationHistory = (body) => {
  return (dispatch) => {
    return new_instance
      .post(`/api/v2/regular/view_regular_transactions`, body, axiosConfig)
      .then((res) => {
        return res.data.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};

export const GetInvoiceAdvance = (body) => {
  return (dispatch) => {
    return instance
      .post(`/api/user/get_invoice_advance`, body, axiosConfig)
      .then((res) => {
        return res.data.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};
export const GetBillToDetails = (body) => {
  return (dispatch) => {
    return instance
      .post(`/api/user/get_invoice_advance`, body, axiosConfig)
      .then((res) => {
        dispatch({ type: "BILLTODATA", payload: res.data.data });
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};

export const GetInvoiceAdvanceMany = (body) => {
  return (dispatch) => {
    return instance
      .post(`/api/user/get_invoice_advance_many`, body, axiosConfig)
      .then((res) => {
        return res.data.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};

export const InvoicePdf = (body) => {
  return instance
    .post(`/api/user/upload_invoice_pdf/invoice`, body, axiosConfig)
    .then((res) => {
      return res.data.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const uploadImage = (body) => {
  return (dispatch, getState) => {
    return axios
      .post(baseUrl() + `/api/users/upload_multiple/chat`, body)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};
export const LastInvoiceHistory = (venue_id, body) => {
  return (dispatch) => {
    return instance
      .post(`/api/user/invoice_date_by_group_id/${venue_id}`, body, axiosConfig)
      .then((res) => {
        return res.data.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};
export const UpdateAdvance = (body) => {
  return (dispatch) => {
    return instance
      .post(`/api/user/sub_invoice_amount`, body, axiosConfig)
      .then((res) => {
        return res.data.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};

export const RepeatBookingDetails = (body) => {
  return (dispatch, getState) => {
    dispatch({ type: "REPEAT_BOOKING_EDIT_DATA", payload: body });
  };
};
export const selectedVenuesFunction = (body) => {
  return (dispatch, getState) => {
    dispatch({ type: "SELECT_VENUE_LIST", payload: body });
  };
};

export const editProfileFunction = (body) => {
  return (dispatch, getState) => {
    dispatch({ type: "EDIT_PROFILE_VENUE", payload: body });
  };
};

export const BookingDataFunction = (body) => {
  return (dispatch, getState) => {
    dispatch({ type: "BOOKING_DATA_FUNCTION", payload: body });
  };
};

export const GameWalletDataFunction = (body) => {
  return (dispatch, getState) => {
    dispatch({ type: "GAME_WALLET_DATA_FUNCTION", payload: body });
  };
};

export const ReportUserFunction = (body) => {
  return (dispatch, getState) => {
    dispatch({ type: "REPORT_DATA_FUNCTION", payload: body });
  };
};

export const calenderChosenDateFunction = (body) => {
  return (dispatch, getState) => {
    dispatch({ type: "CALENDER_CHOSEN_DATE", payload: body });
  };
};

export const monthDataChangeFunction = (body) => {
  return (dispatch, getState) => {
    dispatch({ type: "MONTH_DATA_CHANAGE", payload: body });
  };
};
export const updateAdmin = (body) => {
  return (dispatch, getState) => {
    dispatch({ type: "UPDATE_USER", payload: body });
  };
};

export const changeAdminDetails = (body) => {
  return (dispatch, getState) => {
    dispatch({ type: "UPDATE_USER", payload: body });
  };
};
export const selectedReferenceIdFunction = (body) => {
  return (dispatch, getState) => {
    dispatch({ type: "SELECT_REFERENCE_ID", payload: body });
  };
};

export const selectedVenueChange = (body) => {
  return (dispatch, getState) => {
    const selectedReferenceId = getState().selectedReferenceId;
    selectedReferenceId["venue"] = body;
    dispatch({ type: "SELECT_REFERENCE_ID", payload: selectedReferenceId });
  };
};
export const Logout = () => {
  return (dispatch) => {
    localStorage.clear();
    dispatch({ type: "LOG_OUT" });
  };
};

/////offers section
export const CreateOffer = (object) => {
  return (dispatch) => {
    return instance
      .post("/api/admin/create_offer", object, axiosConfig)
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };
};
export const currentPositon = (x) => {
  return (dispatch, getState) => {
    dispatch({ type: "CURRENT_POSITION", payload: x });
  };
};
export const changeShowBar = (props) => {
  return (dispatch, getState) => {
    dispatch({ type: "SHOW_BOTTOM_BAR", payload: props });
  };
};
// export const deleteOffer = (object) => {
//   return (dispatch) => {
//     return instance
//       .post("/api/admin/delete_offer", object, axiosConfig)
//       .then((res) => {
//         return res.data.data;
//       })
//       .catch((err) => {
//         return Promise.reject(err);
//       });
//   };
// };

export const AddAdmin = (object) => {
  return (dispatch) => {
    return instance
      .post("/api/admin/add_admin", object, axiosConfig)
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };
};

export const EditAdmin = (id, object) => {
  return (dispatch) => {
    return instance
      .put(`/api/admin/edit_admin/${id}`, object, axiosConfig)
      .then((res) => {
        // let x= [...user.manager,res._id]
        // changeAdminDetails(res.data.data);

        return res.data.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };
};

// export const getVenue = (venue_Array) => {
//   return (dispatch, getState) => {
//     return instance
//       .post("/api/admin/venue_list_by_id", { venue: venue_Array }, axiosConfig)
//       .then((res) => {
//         dispatch({
//           type: "VENUE_DETAILS",
//           payload: res.data.data,
//         });
//         return res;
//       })
//       .catch((err) => {
//         dispatch({
//           type: "VENUE_DETAILS_ERROR",
//           payload: err.response,
//         });
//         return Promise.reject(err);
//       });
//   };
// };
export const EditAdminManger = (id, object) => {
  return instance
    .put(`/api/admin/edit_admin_manager/${id}`, object, axiosConfig)
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const EditAdminStaff = (id, object) => {
  return instance
    .put(`/api/admin/edit_admin_staff/${id}`, object, axiosConfig)
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const DeleteAdmin = (id) => {
  // return (dispatch) => {
  return instance
    .delete(`/api/admin/delete_admin/${id}`, axiosConfig)
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
  // };
};
export const AddStaff = (object) => {
  // return (dispatch) => {
  return instance
    .post("/api/admin/add_venue_staff", object, axiosConfig)
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
  // };
};
export const EditStaff = (id, body) => {
  // return (dispatch) => {
  return instance
    .put(`/api/admin/edit_venue_staff/${id}`, body, axiosConfig)
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
  // };
};
export const DeleteStaff = (id) => {
  // return (dispatch) => {
  return instance
    .delete(`/api/admin/delete_venue_staff/${id}`, axiosConfig)
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
  // };
};

export const ChangeRole = (id, role) => {
  // return (dispatch) => {
  return instance
    .post(`/api/admin/change_role/${id}`, role, axiosConfig)
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
  // };
};
export const AddManager = (object) => {
  // return (dispatch) => {
  return instance
    .post("/api/admin/add_venue_manager", object, axiosConfig)
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
  // };
};

export const EditManager = (id, body) => {
  // return (dispatch) => {
  return instance
    .put(`/api/admin/edit_venue_manager/${id}`, body, axiosConfig)
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
  // };
};
export const DeleteManager = (id) => {
  // return (dispatch) => {
  return instance
    .delete(`/api/admin/delete_venue_manager/${id}`, axiosConfig)
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
  // };
};

export const EditOffer = (id, object) => {
  return (dispatch) => {
    return instance
      .post(`/api/admin/edit_offer/${id}`, object, axiosConfig)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };
};
export const EditOffersList = (id, object) => {
  return (dispatch) => {
    return instance
      .post(`/api/v2/venue/venue_active_offer/${id}`, object, axiosConfig)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };
};
export const DeleteOffer = (id) => {
  return (dispatch) => {
    return instance
      .post(`/api/admin/delete_offer/${id}`, {}, axiosConfig)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };
};

export const OffersList = (body) => {
  return (dispatch) => {
    if (axiosConfig.headers["x-access-token"] !== "") {
      axiosConfig.headers["x-access-token"] = localStorage.getItem("token");
    }
    return (
      instance
        // {id:body.id,status:body.status}

        .post(`/api/v2/venue/venue_active_offer`, { venue_id: body.id }, axiosConfig)  /*api/admin/offers_list_by_venue*/
        .then((res) => {
          return res.data.data;
        })
        .catch((err) => {
          return Promise.reject(err);
        })
    );
  };
};

export const InactiveoffersList = (body) => {
  return (dispatch) => {
    if (axiosConfig.headers["x-access-token"] !== "") {
      axiosConfig.headers["x-access-token"] = localStorage.getItem("token");
    }
    return (
      instance
        // {id:body.id,status:body.status}
        .post(`/api/v2/venue/venue_inactive_offer`, {venue_id: body.id }, axiosConfig)  /*api/admin/offers_list_by_venue*/
        .then((res) => {
          return res.data.data;
        })
        .catch((err) => {
          return Promise.reject(err);
        })
    );
  };
};
export const viewExpenses = (body) => {
  return (dispatch, getState) => {
    return instance
      .post(`/api/test/view_expense`, body, axiosConfig)
      .then((res) => {
        // dispatch({ type: "VENUE_EXPENSE", payload: res.data.data });
        return res.data.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };
};

export const saveExpenses = (body) => {
  return instance
    .post(`/api/test/save_expense`, body, axiosConfig)
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};
export const viewBookingCashFlow = (body) => {
  return (dispatch) => {
    return instance
      .post(`/api/test/view_booking_history`, body, axiosConfig)
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };
};

export const searchCustomerData = (body) => {
  return (dispatch) => {
    return instance
      .post(`/api/v2/customer/search_customer`, body, axiosConfig)
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };
};
 
export const searchCustomerPhone = (body) => {
  return (dispatch) => {
    return instance
      .post(`/api/v2/customer/search_customer_phone`, body, axiosConfig)
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };
};
export const searchCustomerbyName = (body) => {
  return (dispatch) => {
    return instance
      //.post(`/api/admin/search_users_number`, body, axiosConfig)
      .post(`/api/admin/search_users_name`, body, axiosConfig)
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };
};
export const searchUserPhone = (body) => {
  return (dispatch) => {
    return instance
      .post(`/api/v2/user/search_user_phone`, body, axiosConfig)
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };
};

export const collectSplitUpAmount = (users,data,booking_id) =>{
  return (dispatch) =>{
    let body = {user_payments:users,data:data}
    return instance
      .post(`/api/v2/user/collect_splitup_amount/`+booking_id, body, axiosConfig)
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
}

export const collectGuestSplitUpAmount = (body) => {
  return (dispatch) =>{
    return instance
      .post(`/api/v2/game/offline_vm_user_payment`, body, axiosConfig)
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
}


////get subscription details
export const getSubscriptionDetails = (venue_id) =>{
  return (dispatch) =>{
    return instance
      .post(`/api/v2/venue/get_subscription_details/`+venue_id, {}, axiosConfig)
      .then((res) => {
        let active_subscription = res.data.data.filter((key)=> !key.subscription_payment_status && key )
        let past_active_subscription = res.data.data
        dispatch({
          type: "CURRENT_SUBSCRIPITION_DATA",
          payload:active_subscription,
        });
        dispatch({
          type: "ALL_SUBSCRIPITION_DATA",
          payload:past_active_subscription,
        });
        return res.data.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
}




export const selectedPreviousSubscription = (batch) => {
  return (dispatch, getState) => {
    dispatch({ type: "TURFTOWN_PREVIOUS_SUBSCRIPITION", payload: batch });
  };
};


////get subscription details
export const monthAppPayments = (venue_id,body) =>{

  return (dispatch) =>{
    
    dispatch({
      type: "MONTH_SUBSCRIPTION_LOADING",
      payload:true,
    });
    return instance
      .post(`/api/v2/app_payment/month_app_payments/`+venue_id, body, axiosConfig)
      .then((res) => {
        batch(()=>{
          dispatch({
            type: "COMPLETED_PAYMENTS",
            payload:res.data.data.completed_payments,
          });
          dispatch({
            type: "INCOMPLETE_PAYMENTS",
            payload:res.data.data.incomplete_payments,
          });
          dispatch({
            type: "PROCESSING_PAYMENTS",
            payload:res.data.data.processing_payments,
          });
          dispatch({
            type: "MONTH_SUBSCRIPTION_LOADING",
            payload:false,
          });
          
        })
        
        return res.data.data
        
      })
      .catch((err) => {
        return Promise.reject(err);
      });

  }
}

////get subscription details
export const monthAppPayments1 = (venue_id,body) =>{

  return (dispatch) =>{
    
    dispatch({
      type: "MONTH_SUBSCRIPTION_LOADING",
      payload:true,
    });
    setTimeout(()=>{
    return instance
      .post(`/api/v2/app_payment/month_app_payments/`+venue_id, body, axiosConfig)
      .then((res) => {

        instance
        .post(`/api/admin/get_venue/${venue_id}`, {}, axiosConfig)
        .then((res1) => {

        batch(()=>{


          batch(()=>{
            dispatch({
              type: "COMPLETED_PAYMENTS",
              payload:res.data.data.completed_payments,
            });
            dispatch({
              type: "INCOMPLETE_PAYMENTS",
              payload:res.data.data.incomplete_payments,
            });
            dispatch({
              type: "PROCESSING_PAYMENTS",
              payload:res.data.data.processing_payments,
            });
            dispatch({
              type: "MONTH_SUBSCRIPTION_LOADING",
              payload:false,
            });
            dispatch({type:"ACTIVE_VENUE",payload:res1.data.data})

            
          })
          

          
        })
        
        return res.data.data
        
      })
   
      .catch((err) => {
        return Promise.reject(err);
      });
    })
      .catch((err) => {
        return Promise.reject(err);
      });
    },1000)
  }
}

export const setAppPayment=(type)=>{
  return (dispatch) => {
    dispatch({
      type: "APP_PAYMENT_TRANSFFERED",
      payload:type,
    });
  }
}

export const totalTransaction = (venue_id) =>{

  return (dispatch) =>{

    dispatch({
      type: "TOTAL_TRANSACTION_LOADING",
      payload:true,
    });
    return instance
      .post(`/api/v2/app_payment/completed_app_payments/`+venue_id, {}, axiosConfig)
      .then((res) => {

        batch(()=>{
          dispatch({
            type: "TOTAL_TRANSACTION_LOADING",
            payload:true,
          });

          dispatch({
            type: "TOTAL_TRANSACTIONS",
            payload:res.data.data,
          });
        
        })
        
        return res.data.data
        
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
}


export const selectedPreviousSubscription1 = (data, magic_app_payment) => {
  return (dispatch, getState) => {
    if(magic_app_payment.length > 0){
    return instance
      .post(
        `/api/v2/app_payment/fetch_app_payment_with_ids`,
        {ids:magic_app_payment},
        axiosConfig
      )
      .then((res) => {
        batch(()=>{
          dispatch({ type: "TURFTOWN_PREVIOUS_SUBSCRIPITION", payload: data });
          dispatch({type:"BATCH_GAMES",payload:res.data.data})
        })
      })
      .catch((err) => {
        return Promise.reject(err);
      });
    }else {
      dispatch({ type: "TURFTOWN_PREVIOUS_SUBSCRIPTION", payload: data });
      dispatch({type:"BATCH_GAMES",payload:[]})
    }
  };
};


////get subscription details
export const getSubscriptionDetails1 = (venue_id) =>{
  return (dispatch) =>{
    return instance
      .post(`/api/v2/venue/get_magic_subscription_details/`+venue_id, {}, axiosConfig)
      .then((res) => {
        let active_subscription = res.data.data.filter((key)=> !key.subscription_payment_status && key )
        let past_active_subscription = res.data.data
        dispatch({
          type: "CURRENT_SUBSCRIPITION_DATA",
          payload:active_subscription,
        });
        dispatch({
          type: "ALL_SUBSCRIPITION_DATA",
          payload:past_active_subscription,
        });
        return res.data.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
}

export const naiveRound = num => {
  var p = Math.pow(10, 2);
  let x = Math.round(num * p) / p;
  if (x == Math.floor(x)) {
    return x;
  } else {
    let f = x.toString().split('.')[0];
    let y = x.toString().split('.')[1];
    let dummy = num.toString().split('.')[1];
    if (y[1] && dummy[2] && dummy[2] < 5 && parseInt(dummy[2]) !== 0) {
      let z = parseInt(y[1]) + 1;
      return Number(`${f}.${y[0]}${z}`);
    } else if (y[1] && dummy[2] && dummy[2] > 5 && y[1] < 5) {
      let z = parseInt(y[1]);
      return Number(`${f}.${y[0]}${z}`);
    } else {
      return x;
    }
  }
};

export const addClosedTimings = (venue_id,body) =>{
  return (dispatch) =>{
    return instance
      .post(`/api/v2/venue/closed_timings/`+venue_id, body, axiosConfig)
      .then((res) => {
        dispatch({type:"ACTIVE_VENUE",payload:res.data.data})
        return res.data.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
}

export const deleteClosedTimings = (venue_id,body) =>{
  return (dispatch) =>{
    return instance
      .post(`/api/v2/venue/delete_closed_timings/`+venue_id, body, axiosConfig)
      .then((res) => {
        dispatch({type:"ACTIVE_VENUE",payload:res.data.data})
        return res.data.data;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
}

export const editVenueRules = (venue_id,body) =>{
  return (dispatch) =>{
    return instance
      .post(`/api/v2/venue/edit_venue_rules/`+venue_id, body, axiosConfig)
      .then((res) => {
        dispatch({type:"ACTIVE_VENUE",payload:res.data.data});
        return res.data.data;
       
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
}

export const reportPlayers = (body) =>{
  return (dispatch) =>{
    return instance
  //.post(`/api/v2/user/report_unpaid_status`, body, axiosConfig)
  .post(`/api/v2/user/report_a_player`, body, axiosConfig)
  .then((res) => {
    dispatch({ type: "REPORT_DATA_FUNCTION", payload: res.data.data });
    return res.data.data;
  })
  .catch((err) => {
    console.error("API Error:", err);
    return Promise.reject(err);
  });
  }}

  export const ReportUser = (id) => {
    return (dispatch, getState) => {
      return instance
        .get(`/api/v2/user/report_status/${id}`, axiosConfig)
        .then((response1) => {
          return response1.data.data;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    };
  };

  export const updateAutoComplete = ( body) => {
    return (dispatch) => {
      return instance
        .post(`api/v2/bookings/toggle_booking_autocomplete`, body, axiosConfig)
        .then((res) => {
          return res;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    };
  };

  export const getCurrentBalance = (id) => {
    return (dispatch, getState) => {
      return instance
        .get(`/api/v3/payout/getCurrentBalnce/${id}`, axiosConfig)
        .then((response1) => {
          dispatch({ type: "CURRENT_BALANCE", payload: response1.data.current_balance });
          return response1.data.current_balance;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    };
  };

  export const getAllBookings = (id) => {
    return (dispatch, getState) => {
      return instance
        .get(`/api/v3/payout/getAllBookings/${id}`, axiosConfig)
        .then((response1) => {
          dispatch({ type: "ALL_BOOKINGS", payload: response1.data.booking_arr });
          return response1.data.booking_arr;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    };
  };

  export const getNewSubscriptionDetails = (id) => {
    return (dispatch, getState) => {
      return instance
        .get(`/api/v3/payout/getSubscription/${id}`, axiosConfig)
        .then((response1) => {
          dispatch({ type: "NEW_SUBSCRIPTION", payload: response1.data.subscription});
          return response1.data.subscription;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    };
  };

  export const getPayoutList = (id) => {
    return (dispatch, getState) => {
      return instance
        .get(`/api/v3/payout/getPayouts/${id}?payoutid=false`, axiosConfig)
        .then((response1) => {
          dispatch({ type: "PAYOUT_LIST", payload: response1.data});
          return response1;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    };
  };
  export const getInProcessPayouts = (id) => {
    return (dispatch, getState) => {
      return instance
        .get(`/api/v3/payout/getProcessingPayouts/${id}`, axiosConfig)
        .then((response1) => {
          dispatch({ type: "IN_PROCESS_PAYOUT", payload: response1.data});
          return response1;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    };
  };

  export const venue_ad_campaign = (venueId, metricType, metricValue) => {
    return (dispatch) => {
      const url = `/api/admin/venue_analytics?venueId=${venueId}&metricType=${metricType}&metricValue=${metricValue}`;
      return instance
        .get(url, axiosConfig)
        .then((response1) => {
          dispatch({ type: "AD_CAMPAIGN", payload: response1.data.data});
          return response1;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    };
  };