import React, { Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { NavLink, Redirect } from "react-router-dom";
import { Visibility } from "@material-ui/icons";
import { VisibilityOff } from "@material-ui/icons";

import { CSSTransition } from "react-transition-group";
import styles from "./user-jss";
import "../baseText.css";
import { ReactComponent as TurftownlogoIcon } from "../Public/svg/TurftownLogoIcon.svg";
import LoginBackgroundImage from "../Public/images/LoginBackgroundImage.png";
import BeatLoader from "react-spinners/BeatLoader";
import { PopupToast } from "../Custom/PopupToast";
import { ReactComponent as LoginErrorIcon } from "../Public/svg/LoginErrorIcon.svg";

import "../Custom/PopupToast.css";

// validation functions
const required = (value) => (value == null ? "Required" : undefined);
const email = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? "Invalid email"
    : undefined;

class LoginForm extends React.Component {
  state = {
    showPassword: false,
  };

  handleClickShowPassword = () => {
    const { showPassword } = this.state;
    this.setState({ showPassword: !showPassword });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  render() {
    const { classes, deco } = this.props;
    const { showPassword } = this.state;
    return (
      <Fragment>
        {/* <Hidden mdUp>
          <NavLink to="/login" className={classNames(classes.brand, classes.outer)}>
            <img src={logo} alt={"Turftown"} />
            {"Turf Town"}
          </NavLink>
        </Hidden> */}

        <div style={{ width: "100%" }}>
          <span
            style={{
              display: "flex",
              flexDirection: "column",
              paddingLeft: "14px",
            }}
          >
            <TurftownlogoIcon
              fill="#ffffff"
              width={"26%"}
              height={"auto"}
              // style={{ marginBottom: "32px" }}
            />
            <span
              style={{
                fontSize: "26px",
                paddingTop: "20px",
                paddingBottom: "48px",
                color: "#ffffff",
              }}
              className="nexaExtraBold"
            >
              Venue Manager
            </span>
          </span>

          <div>
            <div>
              {/* <FormControl className={classes.formControl}> */}
              <span
                className="login-page-email nexaTextRegular"
                style={{
                  border:
                    this.props.error.message === "admin doesn't exist"
                      ? "1px solid #FFBE43"
                      : "1px solid transparent",
                }}
              >
                <input
                  placeholder="Email ID"
                  type="email"
                  className="nexaBold"
                  style={{
                    border: 0,
                    background: "transparent",
                    color: "#ffffff",
                    outline: 0,
                    fontSize: "15px",
                    width: "100%",
                    borerRadius: "30px",
                    outline: 0,
                  }}
                  onChange={(e) => this.props.onChange(e, "email")}
                />
              </span>
              {/* </FormControl> */}
            </div>
            <div>
              {/* <FormControl className={classes.formControl}> */}
              <span
                className="login-page-password nexaTextRegular"
                style={{
                  border:
                    this.props.error.message === "password incorrect"
                      ? "1px solid #FFBE43"
                      : "1px solid transparent",
                }}
              >
                <input
                  label="Password"
                  className="nexaBold"
                  type={showPassword ? "text" : "password"}
                  style={{
                    border: 0,
                    background: "transparent",
                    color: "#ffffff",
                    fontSize: "15px",
                    outline: 0,
                    width: "100%",
                  }}
                  onChange={(e) => this.props.onChange(e, "password")}
                  placeholder="Password"
                />
                <span
                  style={{ display: "flex" }}
                  aria-label="Toggle password visibility"
                  onClick={this.handleClickShowPassword}
                  onMouseDown={this.handleMouseDownPassword}
                >
                  {showPassword ? (
                    <VisibilityOff
                      style={{ height: "20px" }}
                      fill="rgba(255, 255, 255, 0.7)"
                    />
                  ) : (
                    <Visibility
                      style={{ height: "20px" }}
                      fill="rgba(255, 255, 255, 0.7)"
                    />
                  )}
                </span>
              </span>
              {/* </FormControl> */}
            </div>

            {/* <div className={classes.btnArea}> */}
            <button
              disabled={
                this.props.error === "password incorrect" ||
                this.props.error === "user doesn't exist" ||
                this.props.email.length === 0 ||
                this.props.password.length === 0
              }
              style={{
                color:
                  (this.props.error !== "password incorrect" ||
                    this.props.error !== "user doesn't exist") &&
                  this.props.email.length > 0 &&
                  this.props.password.length > 0
                    ? "#0956e5 "
                    : " #B5B5B5",
                background: "#ffffff",
                border: 0,
                padding: this.props.signInLoader
                  ? "13px 42px 7px 42px"
                  : "10px 42px",
                borderRadius: "81px",
                marginTop: "32px",
                letterSpacing: "0.2px",
                outline: 0,
                fontSize: "16px",
              }}
              className="nexaExtraBold"
              onClick={() => this.props.handleSubmit()}
            >
              {this.props.signInLoader ? (
                <span style={{ transform: "translateY(7px)", width: "68px" }}>
                  <BeatLoader
                    loading={true}
                    size={10}
                    margin={3}
                    color={"#d6d6d6"}
                  />
                </span>
              ) : (
                "Sign In"
              )}
            </button>
            {/* </div> */}
            {/* <p
              style={{
                margin: 0,
                fontSize: "0.75rem",
                textAlign: "center",
                marginTop: 8,
                minHeight: "1em",
                fontFamily: "Open Sans,sans-serif",
                lineHeight: "1em",
                paddingLeft: 5,
                color: "#f44336",
              }}
            >
              {this.props.error == "password incorrect" ||
              this.props.error == "user doesn't exist"
                ? ""
                : this.props.data}
            </p> */}
            <div style={{ marginTop: "48px" }}>
              <Button
                size="small"
                component={NavLink}
                to="/forgot-password"
                style={{
                  color: "#ffffff",
                  textTransform: "none",
                  fontSize: "15px",
                  zIndex: 1,
                  fontFamily: "Nexa Bold",
                  fontWeight: "unset",
                }}
                className="MuiButton-root"
              >
                Forgot your Password?
              </Button>
            </div>
          </div>
          <img
            src={LoginBackgroundImage}
            style={{
              position: "absolute",
              width: "50%",
              bottom: "0",
              right: "0",
            }}
          />
        </div>
        <CSSTransition
          in={this.props.popupToast}
          timeout={1000}
          unmountOnExit
          classNames="popupToastContainer"
        >
          <PopupToast
            className="popupToastContainer"
            close={this.props.setPopupToast}
            icon={<LoginErrorIcon width={24} />}
            error={this.props.error.message === "admin doesn't exist" ? "This userID doesn't exist!" : "Password is Incorrect!"}
          />
        </CSSTransition>
      </Fragment>
    );
  }
}

export default withStyles(styles)(LoginForm);
