import React from "react";
import {
  withTheme,
  withStyles,
  createMuiTheme,
  MuiThemeProvider
} from "@material-ui/core/styles";
import applicationTheme from "./applicationTheme";

const styles = {
  root: {
    width: "100%",
    minHeight: "100%",
    marginTop: 0,
    zIndex: 1,
    backgroundColor:"#fff"
  }
};

export const AppContext = React.createContext();

class ThemeWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageLoaded: true,
      theme: createMuiTheme(applicationTheme("blueTheme", "light"))
    };
  }

  componentWillMount = () => {
    this.onProgressShow();
  };

  componentDidMount = () => {
    this.playProgress();
  };

  componentWillUnmount() {
    this.onProgressShow();
  }

  handleChangeMode = mode => {
    const { color, changeMode } = this.props;
    this.setState({ theme: createMuiTheme(applicationTheme("blueTheme", "light")) });
    changeMode(mode);
  };

  onProgressShow = () => {
    this.setState({ pageLoaded: true });
  };

  onProgressHide = () => {
    this.setState({ pageLoaded: false });
  };

  playProgress = () => {
    this.onProgressShow();
    setTimeout(() => {
      this.onProgressHide();
    }, 100);
  };

  render() {
    const { classes, children } = this.props;
    const { pageLoaded, theme } = this.state;
    return (
      <MuiThemeProvider theme={theme}>
        <div className={classes.root}>
          {/* <Loading
            show={pageLoaded}
            color="rgba(255,255,255,.9)"
            showSpinner={false}
          /> */}
          <AppContext.Provider value={this.handleChangeMode}>
            {children}
          </AppContext.Provider>
        </div>
      </MuiThemeProvider>
    );
  }
}



// const dispatchToProps = dispatch => ({
//   changeMode: bindActionCreators(changeModeAction, dispatch)
// });

// const ThemeWrapperMapped = connect(
//   mapStateToProps,
//   dispatchToProps
// )(ThemeWrapper);
export default withStyles(styles, { withTheme: true })(ThemeWrapper);


// export default withTheme()(withStyles(styles)(ThemeWrapper));
